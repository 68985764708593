import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import termsImg from '../assets//images/terms-of-service.jpg'

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms & Conditions" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Terms & Conditions" 
            />
            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <p>
                                    Valid from: 10/01/2018<br/>
                                    Provider: visionar GmbH<br/>
                                    Mitterweg 16/II,<br/>
                                    A-6020 Innsbruck
                                </p>
                                <p>
                                    Commercial register number: 402718h<br/>
                                    Commercial register court: Regional court Innsbruck<br/>
                                    Trade: Services in the field of automatic data processing and information technology (free trade)<br/>
                                    Sales tax identification number: ATU68204588<br/>
                                    (hereinafter simply referred to as “visionar”)
                                </p>
                                <h3>Preamble</h3>
                                <p>
                                    Subject of this contract is the granting of a license right to the software „visionar app“ for a fee and the granting of rights to use the web-based service “visionar” including the associated user documentation (https://docs.visionar.com). As such, it is a full-service solution for augmented reality. Integrated web-based tools allow for content to be uploaded and AR apps to be created. Thus, videos, advertising subjects, links and sounds can be linked to a pre-defined target image.
                                    The software product and the user documentation are delivered online upon conclusion of the contract. The source code is not subject of this contract.
                                </p>
                                
                                <h3>A) Scope of application</h3>
                                <ol>
                                    <li>The following terms and conditions apply to all services offered by visionar GmbH and govern their use.</li>
                                    <li>By placing an order, the customer expressly accepts these terms and conditions.</li>
                                </ol>
                                
                                <h3>B) Registration / conclusion of contract</h3>
                                <ol>
                                    <li>To use the services of visionar an order and a registration at www.visionar.com are required. The order is an offer by the customer to visionar to conclude a contract with the customer.</li>
                                    <li>The customer is obliged to provide truthful and complete information when ordering and registering. Visionar reserves the right to check the correctness of information provided by the customer and to terminate the business relationship prematurely in the event that untrue information has been provided.</li>
                                    <li>There is no right to conclude a contract. Visionar is entitled to refuse the conclusion of a contract without giving reasons.</li>
                                    <li>When ordering and registering at www.visionar.com a confirmation e-mail will be sent to the e-mail address provided by the customer to confirm the registration and the conclusion of the contract. Only with the confirmation of the activation links sent to the customer the registration process is complete. If the customer has not received a confirmation e-mail within 24 hours after submitting the offer, he can contact visionar via the e-mail address provided and visionar will try to find the error in the registration process as quickly as possible and offer a solution.</li>
                                    <li>The confirmation by visionar (acceptance of the customer’s offer) creates a contractual relationship between visionar and the customer which is governed by the provisions of these terms and conditions.</li>
                                    <li>After activation visionar’s services shall be available to the customer and the customer shall receive the login data (URL, password, login ID) to his user account (“account”) with which he can log on.</li>
                                </ol>
                                
                                <h3>C) Services / subject of the contract</h3>
                                <ol>
                                    <li>During the contractually agreed term, visionar grants the customer a geographically unlimited, non-exclusive right (license) to the software visionar app according to the offer or acknowledgement of receipt including documentation for use and contractually agreed web storage space on the Internet. This is associated with a number of targets and web memory for data storage agreed in accordance with the offer selected by the customer. Apps created with visionar can only be distributed in those countries where Apple and Google facilitate it. Visionar has no influence of any kind on these country restrictions and any changes to the country list are beyond visionar’s control.</li>
                                    <li>Visionar will perform a one-time setup of a customer database to store the customer data on the visionar server in encrypted form. Due to the encryption, visionar is not able to view the recorded content itself, the corresponding key is transmitted to the individual customer.</li>
                                    <li>The customer is not authorized to copy, edit, analyze or decompile the software. § 40 e of the copyright act remains unaffected. The customer is only permitted to edit visionar’s software products, if this is necessary for the intended use by the licensee, in particular for error correction or adaptation to the needs of the customer’s system landscape.</li>
                                    <li>Visionar offers the provision of access to the data of the user on the visionar server.</li>
                                    <li>The non-availability of the infrastructure due to planned maintenance work (daily from 06:00 – 07:00 CET) does not count as downtime. Unscheduled maintenance work will be announced by visionar as far as possible in advance.</li>
                                    <li>If and as long as obligations cannot be met in due time or in an orderly manner due to force majeure, such as war, terrorism, natural disasters, fire, strike, lockout, embargo, sovereign intervention, loss of power supply, loss of means of transport, loss of telecommunication networks or data lines, legal changes affecting the services after conclusion of the contract or other non-availability of products, this shall not constitute a breach of contract.</li>
                                    <li>The customer is entitled to use the software to edit and create results and files for his own purposes.</li>
                                    <li>
                                        Visionar provides the customer with a web-based system including a local app for a fee with which the customer
                                        <ol type="a">
                                            <li>can upload content as “image targets” to the web storage space he has obtained for a certain time.</li>
                                            <li>can link the uploaded image targets with content (videos, advertising subjects, links, sounds, etc.) by using the local visionar app.</li>
                                            <li>end users can activate the associated content as an AR app by technically capturing image targets.</li>
                                        </ol>
                                    </li>
                                    <li>The visionar app can be used to manage the content owned by the customer.</li>
                                    <li>With respect to the technical and economic development of the Internet, to statutory provisions or legal requirements and to the advancement of own and third-party products, visionar is entitled to make changes to the services provided at any time.</li>
                                    <li>Daily / weekly performance of multi-level backups of all database data of the content stored by the customer on servers of visionar (in encrypted form).</li>
                                </ol>
                                
                                <h3>D) Fees / due date / change of fees</h3>
                                <ol>
                                    <li>Amount / due date:
                                        <ol type={"a"}>
                                            <li>The fee depends on the product chosen by the customer and confirmed by visionar in their offer confirmation.</li>
                                            <li>The settlement is made monthly in advance and is due for payment within 7 days.</li>
                                        </ol>
                                    </li>
                                    <li>All payments of the customer to visionar are made by using the payment provider STRIPE. Its general terms and conditions are available at https://stripe.com/en-at/privacy</li>
                                    <li>If the customer is a business within the meaning of the Commercial Code, the customer shall be obliged to pay default interest at a rate of 9.2% per year above the respective base interest rate in the event of a default in payment. In all other cases the statutory provisions shall apply.</li>
                                    <li>The customer shall not be entitled to set-off unless otherwise stipulated by law.</li>
                                    <li>Visionar is entitled to change the fees described in Section D). Increases or reductions of these fees are to be announced to the customer in writing (by e-mail to the e-mail address provided by the customer). They become effective if the customer does not object in writing within 14 days after sending the change letter. If the customer objects, visionar shall be entitled to dissolve this contract at the end of the period for which the customer has already paid the agreed fee.</li>
                                </ol>
                                
                                <h3>E) System requirements and customer obligations and duties</h3>
                                <ol>
                                    <li>The establishment and maintenance of a sufficiently fast and available Internet connection is the responsibility of the customer</li>
                                    <li>The customer has to take care of his own infrastructure (software and hardware, such as app-enabled smartphones, printer, barcode scanner, touchscreens, etc.) at his own cost and risk; in particular he has to make sure that the necessary connections (e.g. for data lines) are available. Visionar does not assume any responsibility for the infrastructure of the customer.</li>
                                    <li>The customer has to make sure that his infrastructure (software and hardware, such as app-enabled smartphones, printer, barcode scanner, touchscreens, etc.) is suitable to use the visionar service. He shall always maintain or have maintained the software used by him in a timely and orderly manner. In addition, he must ensure that back-up copies of the data he has collected are made.</li>
                                    <li>In order to be able to use visionar’s services to the full extent the customer has to use the (browser) technologies and the minimum version identification that visionar announces on its website www.visionar.com in the “Tech & Specs” Section. If other technologies are used, the customer may not be able to use visionar’s services to the full extent.</li>
                                    <li>The customer is obliged to protect his connection, end devices and login data against unauthorized access. The customer acknowledges the following:
                                        <ol type={"a"}>
                                            <li>that storing login data (URL, password, login ID, etc.) and other confidential information on the hard drive of a PC is not safe;</li>
                                            <li>that by accessing data from the Internet, viruses, trojan horses and other malware or other components can be transferred to his end device, which may have a negative effect on his data or may lead to the misuse of his login data and that this can be done by “hackers”.</li>
                                        </ol>
                                    </li>
                                    <li>The customer has to keep the login data (URL, password, login ID) strictly confidential and protect it from unauthorized access by third parties. He may not disclose this login data to anyone, not even visionar staff. If the customer has reason to believe that his login data (URL, password, login ID) has become known to third parties in any way, he is obliged to change the password immediately. The customer is obliged to immediately report to visionar any suspicion that his access data or other confidential information may have become known to unauthorized third parties. The customer shall be liable for damages incurred by visionar due to inadequate confidentiality of the access data by the customer or due to them being passed on to third parties, due to late notification of a corresponding suspicion that data may have become known to unauthorized third parties or due to the failure to secure his end devices and systems.</li>
                                    <li>The customer acknowledges that
                                        <ol type={"a"}>
                                            <li>all ownership rights to the server belong exclusively to visionar. The customer has no rights to it and also no right to access the premises in which the servers are located and no right to access them;</li>
                                            <li> in the event of excessive data transfer the visionar servers may be overloaded and may therefore eventually not function. Any claims against visionar in this respect are excluded.</li>
                                        </ol>
                                    </li>
                                    <li>The customer is obliged to use the services provided by visionar
                                        with their intended purpose and while using it to refrain from actions that could harm or endanger visionar and/or other customers and/or restrict the availability of the services for other customers. Intended use also includes compliance with all notices, recommendations and similar that visionar provides at the time of the conclusion of the contract or thereafter, on its homepage, in user manuals and/or other documents made available to the customer.</li>
                                    <li>Visionar offers customers the possibility to process contents in their own visionar database. The customer guarantees that he will not misuse visionar’s services. In particular, the customer has to comply to the following rules:
                                        <ol type={"a"}>
                                            <li>The customer bears sole responsibility for the content stored in his user account. The customer undertakes to comply with all relevant statutory provisions (such as levy-, tax-, and fee-scale provisions, e.g. Federal Tax Code [BAO], Income Tax Act [EStG], Value Added Tax Act [UStG]; Accounting regulations of the Commercial Code [UGB]; the Penal Code [StGB]; the Copyright Act [UrhG]; the Unfair Competition Act [UWG]; the Data Protection Act 2000 [DSG 2000]; the General Data Protection Regulation [GDPR]; personal rights, etc.) when posting content to a user account.</li>
                                            <li>The customer has to ensure compliance with any legal storage obligations (e.g. § 132 BAO) himself. Visionar has no obligation to do so.</li>
                                        </ol>
                                    </li>
                                    <li>The customer guarantees not to upload data onto the visionar database or the visionar web storage space booked from visionar for a certain time and fee which contains viruses (infected software) or software and content for which third parties have copyrights – unless the customer has obtained the necessary rights or the consent of the entitled party.</li>
                                    <li>The customer is obliged to continuously inform himself about scheduled maintenance work on the homepage www.visionar.com.</li>
                                    <li>The customer is obliged to ensure that he is entitled to the lawful processing of the respective results and files and that he does not violate statutory provisions or the rights of third parties.</li>
                                    <li>The customer will not develop content or provide any content that is suitable to negatively influence the health and well-being of the customer’s (sub-) customers. In particular, this includes disturbing, terrifying, pornographic, frightening or similar content, as well as all content that could trigger an epileptic reaction in the viewer.</li>    
                                </ol>
                                
                                
                                
                                
                                {/*To Be Continued*/}
                                <h3>F) Usage rights and copyrights</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>G) Availability / Warranty</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                <h3>H) Liability</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                <h3>I) Shutdown of service</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>J) Amendment of terms and conditions</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>K) Duration / termination of the contract</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>L) Data retrieval after contract termination</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>M) Discretion, privacy protection and confidentiality</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>N) Place of fulfillment, place of jurisdiction, choice of law, contract language</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>O) Withdrawal right for consumers, see also annexes 1 – 3</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                <h3>P) Final provisions</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>ANNEX 1 – Information on the general existence of the right of withdrawal</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>ANNEX 2: Exemption or omission of the right of withdrawal</h3>
                                <p>1. The following terms and conditions apply to all services offered by visionar GmbH and govern their use.<br/>
                                    2. By placing an order, the customer expressly accepts these terms and conditions.</p>
                                
                                <h3>ANNEX 3: Sample withdrawal form</h3>
                                <p>If you want to withdraw from the contract, please take over the following text, fill in this form and send it back to us</p>
                                <blockquote className="blockquote">
                                    <p>To<br/>
                                        visionar GmbH<br/>
                                        Mitterweg 16/II,<br/>
                                        A-6020 Innsbruck<br/>
                                        E-mail: support(at)visionar.com
                                    </p>
                                    <br/>
                                    <p>
                                        I/we (*) hereby withdraw from the contract concluded by me/us (*) for the provision of the following services<br/>
                                    </p>
                                    <ul>
                                        <li>Ordered on (*)/received on (*)</li>
                                        <li>Name of the consumer(s)</li>
                                        <li>Address of the consumer(s)</li>
                                        <li>Signature of the consumer(s) (only for paper communication)</li>
                                        <li>Date</li>
                                    </ul>
                                    <p>…, on (Validity from date…..)</p>
                                </blockquote>
                                <p>(*) Delete as applicable</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;